.settings-page{
    position: absolute;
    top: 50px;
    bottom: 0;
    overflow: auto;
    margin: 0 auto;
    right: 0;
    background-color: #fff;
    color: #212529;
    padding: 0 20px 40px 20px;
    &.show-tool{
        left: 70px;
    }
    &.hide-tool{
        left: 0;
    }
    .border-bottom{
        border-color: #122c44 !important;
    }
    h3{
        font-size: calc(1.3rem + .6vw);
        font-weight: 500;
    }
    h4{
        font-size: calc(1.275rem + .3vw);
    }
    p{
        margin-top: 0;
        margin-bottom: 1rem;
    }
    a{
        color: #0d6efd;
    }
    .settings-content{
        max-width: 650px;
        width: 100%;
        .card{
            padding: 5px 10px;
            -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 5px;
            border: 0;
        }
        .col-form-label {
            font-weight: 500;
            min-width: 120px;
        }
        .unsubscribe-text{
            max-width: 285px;
            width: 100%;
        }
    }
}
