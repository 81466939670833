.conversation-panel{
    background-color: #fff;
    height: 100%;
    margin-right: 3px;
    position: relative;
    &.single-panel{
      width: calc(100vw - 343px);
      .conversation-body{
        padding: 5rem 10rem;
      }
    }
    &.dual-panel{
      width: calc(50vw - 150px);
    }
    &.left-navbar{
      width: calc(50vw - 135px);
    }
    &.message-view{
      width: calc(100vw - 272px);
      .conversation-body{
        padding: 5rem 10rem;
      }
    }
    .header-panel{
        background-color: #09a6f7;
        color: #fff;
        font-size: 16px;
        padding: 8px;
    }
    .conversation-body{
        padding: 1.5rem;
        font-weight: 500;
        overflow: auto;
        position: absolute;
        left: 0;
        right: 0;
        top: 40px;
        bottom: 0;
        .display-time{
            font-size: 12px;
            color: #666;
        }
    }
    .footer-panel{
        background-color: #666;
        color: #fff;
        font-size: 16px;
        height: 30px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
    }
    .message-text {
        margin-bottom: 5px;
        word-wrap: break-word;
        font-size: 11px;
      }
      .timestamp {
        display: block;
        color: #999;
        font-size: 0.9em;
        margin-bottom: 2px;
      }
      .user-message {
        align-self: flex-end;
        display: block;
        max-width: 60%;
        float: right;
        clear: both;
        .messageText{
            background-color: #a5cfe94a;
            border-radius: 2px;
            padding: 4px 5px;
            margin-right: 20px;
        }
        svg{
            font-size: 15px;
            color: #666;
            position: absolute;
            // top: -3px;
        }
      }
    
      .received-message {
        align-self: flex-start;
        margin-right: auto;
        max-width: 60%;
        float: left;
        display: block;
        clear: both;
        .messageText{
            border: 1px solid #ccc;
            padding: 4px 8px;
            border-radius: 3px;
            margin-left: 20px;
        }
        svg{
            font-size: 21px;
            color: #666;
            position: absolute;
            // top: -3px;
        }
      }
      .senderName {
        // padding-left: 8px;
        padding-left: 20px;
        padding-right: 5px;
      }
      .receiverName {
        // padding-left: 8px;
        padding-right: 20px;
        padding-left: 5px;
      }
      .no-data-found {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        margin-top: -10px;
        font-size: 12px;
      }
      .m-t-5px {
        margin-top: 5px;
      }
      .translation-header {
        background-color: #e5f1f9;
        margin: -4px -8px;
        padding: 4px 8px;
      }
      .translation-header-receiver {
        background-color: #a5cfe94a;
        margin: -4px -4px 2px -4px;
        padding: 4px 4px;
      }
      .translation-body {
        padding: 6px 4px;
        margin: -4px -4px;
      }
      .translation-body-receiver{
        background-color: #f0f0f0;
        padding: 6px 4px;
        margin: -4px -4px;
      }
      .translated-icon-sender{
        width: 21px;
        position: absolute;
        padding-left: 5px;
      }
      .translated-icon-receiver{
        width: 21px;
        position: absolute; 
        padding: 0px 3px;
      }
}