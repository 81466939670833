.my-custom-control{
    box-shadow: 0 0 3px #999;
    max-width: 350px;
    padding: 10px;
    position: fixed;
    top:auto;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 10;
    background-color: #fff;
    pointer-events: auto;
    height: 88px;
}
.my-custom-control input[type="range"].doppler-slider {
  margin: 0;
  width: 85.6%;
  position: absolute;
  left: 46px;
  top: 26px;
  right: 0;
  z-index: 1;
  height: 12px;
  background-color: transparent !important;
}

.my-custom-control input[type="range"].doppler-slider:hover {
  opacity: 1;
}

/* progress bar in analytics and weather popup for chrome and edge- NSPL and SMAN */
input[type="range"] {
  -webkit-appearance: none;
  margin: 20px 10px;
  width: 90%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: #03a9f4;
  border-radius: 25px;
}

input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #03a9f4;
}

/* progress bar in analytics and weather popup for mozilla firefox- NSPL and SMAN */
input[type="range"]::-moz-range-track {
   width: 100%;
   height: 4px;
   cursor: pointer;
   animate: 0.2s;
   background: #03a9f4;
   border-radius: 25px;
 }

input[type="range"]::-moz-range-thumb {
   height: 20px;
   width: 20px;
   border-radius: 50%;
   background: #fff;
   box-shadow: 0 0 4px 0 rgba(0, 0, 0, 1);
   cursor: pointer;
   -webkit-appearance: none;
   margin-top: -8px;    
 }

.play-option{
  width: 25px;
  position: absolute;
  left: 18px;
  top: 19px;
  cursor: pointer;
  z-index: 1;
}

.play-option svg path{
    fill:#999;
}
.my-custom-control .time-zone{
  position: relative;
  padding-top: 25px;
  color: #666;
  font-size: 14px;
  height: 18px;
}
.my-custom-control .current-time{
  position: absolute;
  left: 22px;
}
.my-custom-control .end-time{
    position: absolute;
    right: 0;
}
#pause-icon{
  width: 16px;
  position: absolute;
  top: 0;
  left: 5px;
  height: 25px;
}
#pause-icon{
  display: none;
}
.my-custom-control .display-time{
  font-size: 14px;
}
.doppler{
  position: relative;
  top: 3px;
}
.refresh-weather{
  position: absolute;
  left: 0px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
}
.refresh-weather img{
  width: 17px;
}