.sideMenu {
  margin-right: 0;
  .menu-divider {
    height: 1px;
    margin: 8px 3px;
    background-color: #c8c8c8;
  }
}

.sideIcon {
  color: #666666;
  width: 65px;
  height: 40px;
  vertical-align: middle;
  display: flex;
  text-align: center;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.menu-tooltip {
  .tooltip-inner {
    background-color: #122c44 !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 6px;
  }
}

.tooltip {
  opacity: 1 !important;
}

.tooltip-arrow {
  margin-top: 3px !important;
}

.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #122c44 !important;
}