
.mapboxgl-popup{
        z-index: 3;
}
.mapboxgl-popup-content{
        pointer-events: auto;
        min-width: 200px;
        padding: 10px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        letter-spacing: 0px;
        color: #333333;
        .mapboxgl-popup-close-button{
            font-size: 16px;
            color: #666;
            padding-right: 8px;
            &:hover{
                background-color: transparent;
                outline: none;
            }
        }
}
.marker_popup{
        padding:0;
        &.address-marker{
            margin: 0;
            .pagination-reportbtn{
                margin-right: 5px;
            }
        }
        .close-popup{
            position: absolute;
            top: 0;
            right: 5px;
            svg{
                color: #666;
                stroke-width: 1;
            }
        }
        .title{
            .active{
                color: #0068ae;
            }
            b{
                padding-right: 21px;
            }
        }
}
.pagination{
        justify-content: flex-end;
        color: #000;
        font-weight: 500;
        // padding-right: 10px;
        margin-bottom: 0;
        height: 17px;
        li{
            padding: 0 4px;
            // width: 15px;
            a{
                color: #333;
                font-weight: 600;
                &:hover{
                    text-decoration: none;
                }
                &.arrow-right, &.arrow-left{
                    font-size: 0;
                    position: relative;
                    &::after{
                        content: '>';
                        font-size: 14px;
                        color: #0068ae;
                    }
                }
                &.arrow-left{
                    font-size: 0;
                    position: relative;
                    &::after{
                        content: '<';
                        font-size: 14px;
                        color: #0068ae;
                    }
                }
            }
            &.active{
                a{
                    color: #0068ae;
                }
            }
            &.disabled{
                a{
                    &.arrow-right, &.arrow-left{
                        &::after{
                            color: #999;
                            cursor: not-allowed;
                        }
                    }
                }
            }
            &:last-child{
                display: none;
            }
        }
}
.report-menu{
    border-bottom: 1px solid #707070;
    padding-bottom: 5px;
    .report-btn{
        width: 61px;
        height: 21px;
        background: #2F78DD 0% 0% no-repeat padding-box;
        border-radius: 3px;
        font: normal normal normal 12px/14px Helvetica;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-align: center;
        line-height: 20px;
    }
}
.psap-map, .psap-sattelite-map{
    .routing-popup{
        &.mapboxgl-popup-anchor-bottom{
            .mapboxgl-popup-tip{
                border-top-color: #000;
                &::after{
                border-color: #000 transparent transparent transparent;
                }
            }
        }
        &.mapboxgl-popup-anchor-top{
            .mapboxgl-popup-tip{
                border-bottom-color: #000;
                &::after{
                    border-color: transparent transparent #000 transparent;
                }
            }
        }
        &.mapboxgl-popup-anchor-right{
            .mapboxgl-popup-tip{
                border-left-color: #000;
                &::after{
                    border-color: transparent transparent  transparent #000;
                }
            }
        }
        &.mapboxgl-popup-anchor-left{
            .mapboxgl-popup-tip{
                border-right-color: #000;
                &::after{
                    border-color: transparent #000 transparent  transparent ;
                }
            }
        }
        .mapboxgl-popup-content{
            background: #000;
            color: #fff;
            min-width: 100px;
            width: 120px;
            border-radius: 3px;
            margin: 0 auto;
            text-align: center;
            font-weight: 600;
            .traffic-status{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: inline-block;
                background-color: #28a745;
                margin-right: 5px;
            }
            .btn-danger{
                padding: 2px 12px;
                font-size: 12px;
                border-radius: 18px;
                margin: 5px 0;
            }
            .close-popup{
                color: #fff;
                opacity: .8;
                position: absolute;
                right: 5px;
                top: 0;
                svg{
                    stroke-width: 2;
                }
            }
        }
    }
    .cluster-popup{
        .mapboxgl-popup-content{
            border-radius: 5px;
            min-width: inherit;
            padding: 8px 10px;
            .popup-title{
                text-align: center;
                font-size: 12px;
            }
            .inginition-status{
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #99999B;
                margin-right: 4px;
                &.on{
                    background-color: #4DD500;
                }
                &.off{
                    background-color: #FF2615;
                }
            }
        }
        &.mapboxgl-popup-anchor-bottom{
            .mapboxgl-popup-tip{
                &::after{
                    border-width: 11px 11px 0;
                    left: -10px;
                     top: -5px;
                }
            }
        }
        &.mapboxgl-popup-anchor-top{
            .mapboxgl-popup-tip{
                &::after{
                    border-width: 0 11px 11px;
                    left: -13px;
                    top: -4px;
                }
            }
        }
        &.mapboxgl-popup-anchor-right{
            .mapboxgl-popup-tip{
                &::after{
                    border-width: 11px 0 11px 11px;
                    left: -6px;
                    top: -12px;
                }
                &::before{
                    top: -11px;
                }
            }
        }
        &.mapboxgl-popup-anchor-left{
            .mapboxgl-popup-tip{
                border: 8px solid transparent;
                &::after{
                    border-width: 11px 11px 11px 0;
                    left: 0;
                    top: -9px;
                }
                &::before{
                    top: -8px;
                }
            }
        }
    }
    .donut-popup{
        min-width: 130px;
        h4{
            font: normal normal normal 14px/14px Helvetica;
            letter-spacing: 0.3px;
            color: #2F78DD;
            text-align: center;
            padding-bottom: 3px;
            border-bottom: 1px solid #888888;
        }
        .ignition-details{
            padding-bottom: 3px;
            margin-bottom: 2px;
        }
        .count-text{
            font-size: 14px;
        }
        .street-label{
            min-width: 90px;
            display: inline-block;
        }
        .count-list{
            min-width: 35px;
            font-size: 11px;
            color: #99999B;
            font-weight: bold;
            .on{
                color: #4DD500;
            }
            .off{
                color: #FF2615;
            }
        }
        .status{
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #99999B;
            display: inline-block;
            margin-right: 3px;
            &.on{
                background-color: #4DD500;
            }
            &.off{
                background-color: #FF2615;
            }
        }
    }

}
.incident-marker{
    background-repeat: no-repeat;
    background-size: cover;
    width: 39px;
    height: 50px;
    cursor: pointer;
    &.cursor-default{
        cursor: inherit;
    }
    &.incident-marker-sos{
        width: 23px;
        height: 30px;
    }
    &.solacom-marker{
        height: 69px;
        width: 40px;
    }
    &.other-agent-markers{
        width: 25px;
        height: 43px;
    }
}
.street-label{
    color: #888888;
}


