.toggle {
    cursor: pointer;
    font-size: 14px;
    color:#000;
  }
  
  .txt-primary{
    color: #0068a6;
  }
  .sign-up{
    padding-bottom: 60px;
    .form-group{
      max-width: 329px;
    }
  }
  .show-pwd{
    right: 7px;
    top: 31px;
  }
  .sign-in h3 {
    font-size: 24px;
  }

#okta-sign-in focused-input, 
#okta-sign-in input[type=text]:focus, 
#okta-sign-in.auth-container .okta-form-input-field input {
  color: #1d1d21 !important;
}
#okta-sign-in {
  margin: 0 !important;
}

.loaderDiv {
  width: 100px;
  height: 100px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}