.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #122c44;
  min-height: 35px;
  font-weight: normal;
  line-height: 35px;
  font-size: 12px;
  color: #fff;
  z-index: 111;
}