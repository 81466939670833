.header {
  width: 100%;
  background-color: #122c44;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
  z-index: 1111;
  color: #eeeeee;
}
.header-bar {
  width: 100%;
  margin: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-item {
  margin: 0 5px;
  font-size: 12px;
  cursor: pointer;
}
.header-item a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}

.psap-header{
  background-color: #122c44;
  height: 50px;
  display: flex;
  align-items: center;
  font-weight: 500;
  position: relative;
  z-index: 1;
  color: #eeeeee;
  .logo{
      color: #0068aE !important;
      font-size: 1.25rem;
      vertical-align: bottom;
      text-transform: uppercase;
      letter-spacing: .5px;
      font-size: 13px;

      .logo-text{
        display: inline-block;
        font-weight: 500;
        white-space: nowrap;
        margin-right: 5px;
        color: #fff;
      }
      .upper-text{
        font-size: 18px;
      }
  }
  // ES-3184
  .map-style{
    .dropdown-item{
      white-space: nowrap;
      overflow: hidden;
    }
    @-moz-document url-prefix() { 
      .dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        padding-right: 24px;
      }
    }
    .dropdown-menu.show{
      max-height: 250px;
      max-width: fit-content;
      overflow-y: auto;
      top: 14px !important;
    }
    .dropdown-toggle{
      background: transparent !important;
      border: transparent !important;
      font-size: 11px;
      display: flex;
      align-items: center;
      color: #eeeeee;
      padding: 0;
      &::after{
        display: none;
      }
      &:focus{
        box-shadow: none !important;
      }
    }
    .dropdown{
      &.show{
        .dropdown-toggle{
          background: transparent !important;
          border: transparent !important;
          color: #addcf2 !important;
          &:focus{
            box-shadow: none !important;
          }
        }
      }
    }
    .selected {
        color: #addcf2;
        background-color: #007bff;
    }
    &.map-layers{
      &.nav-list{
        .inactive{
          color: #333;
        }
        .inactive, .active{
          font-size: 14px;
        }
        .active{
          color: #0077e5;
        }
        .dropdown-item{
          padding: 0 18px;
          .layer-divider{
            margin: 0 -18px !important;
            padding: 6px 18px !important;
            border-color: #666 !important;
          }
          .nav-list{
            margin: 0;
            border-bottom: 1px solid #ccc;
            padding: 6px 0;
            a {
              text-decoration: none;
            }
          }
          // &:nth-child(2){
          //  .nav-list{
          //   margin: 0 -18px;
          //   padding: 6px 18px;
          //   border-color: #666;
          //  }
          // }
          &:last-child{
            .nav-list{
              border-bottom: 0;
            }
          }
          &:focus, &.active{
            background-color: transparent !important;
          }
        }
        .dropdown-menu{
          border-radius: 0;
          padding: 0;
          border: 1px solid #666;
          border-top: none;
          box-shadow: 0 0 2px #666;
          min-width: 170px;
            .nav-icon{
              margin-right: 15px;
            }
        }
      }
    }
  }
  .nav-list{
    margin-left: 15px;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
    }
    &.active{
      color: #addcf2;
    }
    .inactive{
      color: #eeeeee;
      font-size: 11px;
    }
    .active{
      color: #addcf2;
      font-size: 11px;
    }
    &.camera-btn{
      .nav-icon{
        margin-top: 3px;
      }
    }
    &.tracking-list{
      .dropdown-menu{
        border: 1px solid #666;
        border-top: none;
        box-shadow: 0 0 2px #666;
        border-radius: 0;
        padding: 0;
      }
      .dropdown-item{
        &:nth-child(1){
          border-bottom: 1px solid #000;
        }
        .active{
         color: #0077e5 !important;
         font-size: 14px !important;
        }
      }
    }
  }
  .user-name{
    font-size: 11px;
    font-weight: normal;
    .nav-icon{
      width: 18px;
      margin-right: 5px;
      &.track-icon{
        font-size: 16px;
        width: 18px;
        margin-right: 3px;
        margin-top: 2px;
      }
    }
    .mapstyle-icon{
      width: 70px;
      margin-right: 5px;
    }
    .divider-icon{
      width: 1px;
      height: 22px;
      margin: 20px;
    }
  }

  & .nav-list:last-child {
    & .dropdown-menu.show{
      left: auto !important;
      border-radius: 0;
      border: 1px solid #666;
      box-shadow: none;
      overflow: hidden;
      position: fixed !important;
      right: 15px !important;
      transform: inherit !important;
      top: 50px !important;
    }
    & .dropdown-item {
      display: block;
      width: 100%;
      padding: .25rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: #212529;
      text-align: inherit;
      white-space: nowrap;
      // background-color: transparent;
      border: 0;
    }
  }
}
.comtech-header {
  border-bottom: 5px solid #0068a6;
}
.psap-help{
  padding-right: 25px;
  color: #666;
  display: flex;
  align-items: baseline;
  .help-icon{
    width: 12px;
    height: 12px;
    background: #666;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    font-size: 11px;
    margin-right: 2px
  }
}

.custom-layers{
  padding: 6px 2px;
}

.custom-layer-border-bottom{
  border-bottom: 1px solid;
}

.notification-bell{
  border-left: 1px solid white;
  padding-left: 30px;
  font-size: 15px;
}

.info-circle{
  padding-left: 15px;
  font-size: 16px;
}

.menu-grid {
  width: 220px;
  display: flex;
  align-items: center;
  line-height: 1px;
  font-size: 25px;
}

.gird-fill{
  width: 60px;
  cursor: pointer;
  position: relative;
  text-align: center;
}

.grid-dropdown {
  margin-top: 12px;
}

.dropdown-item-with-border {
  padding: 5px 15px 5px 15px;
  margin-bottom: 5px;
  line-height: 25px;
}

.dropdown-item-with-border:hover {
  background-color: #095a8b; 
  color: white;
}

.text-icon {
  position: relative;
  bottom: 2px;
  min-width: 20px;
}
.selection-text {
  min-width: 80px;
}

.groupName {
  padding-left: 4px;
}

.people-icon {
  color: #B821F7;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}