.calls-left-panel{
    width: 265px;
    left: 0;
    background: #fff;
    top: 0;
    bottom: 0;
    font-weight: 500;
    position: relative;
    height: 100%;
    margin-right: 3px;
    .call-details{
        background: #f0f0f0;
        font-size: 12px;
        padding: 6px 15px;
        display: flex;
        justify-content: space-between;
        .station-label {
            font-size: 11px;
            color: #666564;
            padding-right: 5px;
        }
        .station-id {
            color: #0077e5;
        }
    }
    .calls-list{
        position: absolute;
        top: 36px;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 7px 15px;
        font-size: 12px;
        overflow: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        font-weight: 500;
    }
    .call-btn {
        background-color: #09a6f7;
        color: #fff;
        height: 20px;
        line-height: 18px;
        border-radius: 2px;
        font-size: 11px;
        letter-spacing: 0.4px;
        margin-left: 3px;
        display: inline-block;
        padding: 0 6px;
        position: relative;
        top: -2px;
    }
    .main-info {
        height: 25px;
        line-height: 25px;
        background-color: #3d81c3;
        color: #fff;
        width: 261px;
        padding: 0 15px;
        position: relative;
        left: -15px;
        &.add-info {
            background-color: #63acd6;
        }
        &.end-calls-info{
            background-color: #4b4b4b;
        }
        &::after {
            content: " ";
            position: absolute;
            width: 0;
            height: 0;
            border-left: 25px solid transparent;
            border-right: 25px solid transparent;
            border-top: 25px solid #fff;
            right: -25px;
        }  
    }
    .label {
        color: #666;
        width: auto;
    }
    .no-data-found{
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        text-align: center;
        margin-top: -10px;
        font-size: 12px;
    }
    .event-icon {
        width: 35px;
        height: 35px;
        margin-right: 15px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.answer{
            background-color: #97c8ef;
        }
        &.transfer{
            background-color: #4eb74f;
        }
        &.release{
            background-color: #999999;
        }
        svg{
            color: #fff;
            font-size: 18px;
        }
        &.small-no-bg {
            width: 25px;
            height: 25px;
            background-color: #fff;
            border: 1px solid #666;
        }
        &.speaker-slash, &.microphone-slash {
            margin-left: auto;
            margin-right: 0;
        }
    }
    .service-name{
        color: #3d81c3;
    }
    .transfer-icon{
        width: 35px;
    }
    .agency-icon{
        width: 18px;
    }
    .route-icon{
        width: 18px;
        height: 18px;
        background-color: #3d81c3;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        svg{
            color: #fff !important;
            stroke-width: 2 !important;
            position: relative;
            top: -3px;
        }
    }
    .call-icon{
        width: 45px;
        height: 45px;
        margin-right: 10px;
    }
    .call-mdn {
        font-size: 16px;
    }
    .display-counter {
        font-size: 16px;
        margin-left: 4px;
        width: 50px;
        display: inline-block;
    }
    .disability{
        word-break: break-all;
    }
    .more-btn{
        color: #0077e5;
    }
    .history-details {
        margin-top: 15px;
        padding-bottom: 15px;
        .history-list {
            padding: 2px 0;
            .title {
                font-size: 13px;
                padding: 8px 0;
                border-bottom: 1px solid #ccc;
                .active {
                    color: #3d81c3;
                }
            }
            svg {
                font-size: 11px;
                stroke-width: 1;
                color: #3d81c3;
            }
            .rapisos-logo {
                width: 15px;
                position: relative;
                top: -1px;
            }
        }
        .address-details {
            font-size: 12px;
            padding-top: 15px;
        }
    }
    .rapid-list{
        text-transform: capitalize;
        padding: 5px 0 5px 10px;
        color: #122c44;
    }
    .child-list{
        padding-bottom: 10px;
        &:last-child{
            padding-bottom: 0;
        }
    }
    .end-call-details {
        padding: 8px 10px;
        margin: 5px 10px;
        font-weight: 500;
        font-size: 1rem;
        /* border-bottom: 1px solid darkgray; */
        padding-bottom: 10px;
        background: #e5e5e5;
        &.active{
          outline: 2px solid #f99403;
          border-color: #f99403;
        }
      }
      .noMargin{
        margin: 0;
      }
}

.call-icon-overlay{
    z-index: 2 !important;
}
.active-icon-overlay{
  z-index: 3 !important;
}

/* Solacom Styles */
.uplotted-calls{
    position: fixed;
    bottom: 65px;
    left: 332px;
    width: 60px;
    height: 45px;
    border-radius: 50%;
    color: grey;
    background: url('../assets/icon_unplotted_calls.svg');
    z-index: 9;
    .count{
        position: absolute;
        right: 0;
        top: 12px;
        font-weight: 500;
        width: 30px;
        text-align: center;
    }
}
.unplotted-popup{
    position: fixed;
    left: 277px;
    bottom: 105px;
    top: auto;
    font-size: 12px;
    z-index: 5;
    .mapboxgl-popup-content{
        padding: 0;
        min-width: 130px !important;
    }    
    .call-list{
        padding: 5px;
        border-bottom: 1px solid #ccc;
        text-align: center;
        &:last-child{
            border-bottom: none;
        }
    }
}