body{
    &.incident-bg{
        background-color: #122c44;
        overflow: hidden;
    }
}
.right-section{
  width: 100%;
  margin-right: 3px;
  display: flex;
  height: calc(100vh - 85px);
}

.left-side {
    background: #fff;
    text-align: center;
    flex-basis: 65px;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    margin: 0 3px;
  }
  .sidebar-section-open {
    background: #fff;
    margin-right: 3px;
    position: fixed;
      left: 72px;
      right: auto;
      top: 50px;
      bottom: 35px;
      z-index: 11;
  }
  .content-section{
    width: 100%;
    position: relative;
  } 
  .content-section-hide{
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  } 
  .map-top-section {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 11;
  }

  /* map related styles */
  .mapboxgl-ctrl.mapboxgl-ctrl-group{
    bottom: 25px !important;
  }